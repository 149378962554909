<div class="container">
  <br />
  <div class="container">
    <h1>
      Let's chat!
      <br />
      <small class="text-muted">
        Writing code and exploring new ideas in Vancouver, Canada</small
      >
    </h1>
  </div>
  <br />
  <div class="container">
    <div class="row">
      <div class="col-1">
        <div class="row">
          <fa-icon [icon]="faEnvelope" size="2x"></fa-icon>
        </div>
      </div>
      <div class="col-6">justin.kuan@outlook.com</div>
    </div>
    <br />
    <div class="row">
      <div class="col-1">
        <div class="row">
          <fa-icon [icon]="faLinkedIn" size="2x"></fa-icon>
        </div>
      </div>
      <div class="col-6">
        <a href="https://www.linkedin.com/in/justin-kuan/" target="_blank">
          https://www.linkedin.com/in/justin-kuan/</a
        >
      </div>
    </div>
    <br />
    <div class="row">
      <div class="col-1">
        <div class="row">
          <fa-icon [icon]="faGithub" size="2x"></fa-icon>
        </div>
      </div>
      <div class="col-6">
        <a href="https://github.com/jkuan1" target="_blank"
          >https://github.com/jkuan1</a
        >
      </div>
    </div>
  </div>
</div>
