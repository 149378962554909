<nav class="navbar navbar-light bg-light">
  <span></span>
  <button
    class="navbar-toggler first-button justify-content-end"
    type="button"
    data-mdb-toggle="collapse"
    data-mdb-target="#navbarToggleExternalContent9"
    aria-controls="navbarToggleExternalContent9"
    aria-expanded="false"
    aria-label="Toggle navigation"
    (click)="onClick()"
  >
    <div class="animated-icon1"><span></span><span></span><span></span></div>
  </button>
</nav>
<div [class]="collapseStatus" id="navbarToggleExternalContent9">
  <div class="bg-light shadow-3 p-4">
    <div
      class="nav flex-column nav-pills"
      id="v-pills-tab"
      role="tablist"
      aria-orientation="vertical"
    >
      <!-- <a
        class="nav-link"
        routerLinkActive="active"
        [routerLinkActiveOptions]="{exact: true}"
        id="home-tab"
        data-toggle="pill"
        routerLink="/"
        role="tab"
        >Home</a
      > -->
      <a
        class="nav-link"
        routerLinkActive="active"
        [routerLinkActiveOptions]="{exact: true}"
        id="about-tab"
        data-toggle="pill"
        routerLink="/about"
        role="tab"
        >About</a
      >
      <a
        class="nav-link"
        routerLinkActive="active"
        id="experience-tab"
        data-toggle="pill"
        routerLink="/experience"
        role="tab"
        >Experience</a
      >
      <!-- <a
        class="nav-link"
        routerLinkActive="active"
        id="projects-tab"
        data-toggle="pill"
        routerLink="/projects"
        role="tab"
        >Projects</a
      > -->
      <a
        class="nav-link"
        id="contact-tab"
        routerLinkActive="active"
        data-toggle="pill"
        routerLink="/contact"
        role="tab"
        >Contact</a
      >
    </div>
  </div>
</div>
