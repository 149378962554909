<div class="container-fluid">
  <div class="row">
    <app-navbar></app-navbar>

    <div class="col">
      <nav class="navbar navbar-expand-lg navbar-light bg-light">
        <div class="container-fluid">
          <div>
            <img width = "80" alt="Personal Logo" src="../assets/logo1.png" />

          </div>

          <a
            role="button"
            href="/resume.pdf"
            target="_blank"
            rel="noopener noreferrer"
            class="btn btn-outline-success"
            >Resume</a
          >
        </div>
      </nav>
      <router-outlet></router-outlet>
    </div>
  </div>
</div>

<!-- Footer -->
<!-- <footer>
  <a
    href="https://www.linkedin.com/in/justin-kuan/"
    target="_blank"
    rel="noopener"
  >
    Check out my LinkedIn!
  </a>
</footer> -->
