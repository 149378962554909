<style>
  p {
    color: black;
    font-size: 28px;
  }

  a {
    color: inherit;
  }
</style>

<div class="container">
  <br />
  <div class="container">
    <div class="row">
      <div class="col">
        <img
          src="../../assets/headshot.jpg"
          alt="headshot"
          max-width="400"
          width="100%"
        />
      </div>
      <div class="col">
        <h1>About me</h1>

        <p>
          Hey! My name is Justin Kuan, a 4th year biomedical engineering student
          at the
          <u
            ><strong
              ><a href="https://www.ubc.ca">
                University of British Columbia.
              </a></strong
            ></u
          >
        </p>
        <p>
          I enjoy solving puzzles, whether its a unique brain teaser, or the
          tough technical challenge. That's why my goal to implement solutions
          in the health space has never been a dull journey. On a daily basis, I
          hone my skills to help make the industry a safer, more inclusive, and
          efficient sector.
        </p>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="container">
      <div class="row">
        <h1>Some of the technology I work with include:</h1>
      </div>
      <div class="row">
        <div class="col-1">
          <div class="row">
            <fa-icon [icon]="faPython" size="2x"></fa-icon>
          </div>
        </div>

        <div class="col-6">
          <div class="row">
            <p>Python</p>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-1">
          <div class="row">
            <fa-icon [icon]="faAngular" size="2x"></fa-icon>
          </div>
        </div>

        <div class="col-6">
          <div class="row">
            <p>Angular</p>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-1">
          <div class="row">
            <fa-icon [icon]="faDocker" size="2x"></fa-icon>
          </div>
        </div>

        <div class="col-6">
          <div class="row">
            <p>Docker</p>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-1">
          <div class="row">
            <fa-icon [icon]="faAWS" size="2x"></fa-icon>
          </div>
        </div>

        <div class="col-6">
          <div class="row">
            <p>AWS (Lambda, EC2, S3, VPC, API Gateway, among others)</p>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-1">
          <div class="row">
            <fa-icon [icon]="faC" size="2x"></fa-icon>
          </div>
        </div>

        <div class="col-6">
          <div class="row">
            <p>C++</p>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-1">
          <div class="row">
            <fa-icon [icon]="faJava" size="2x"></fa-icon>
          </div>
        </div>

        <div class="col-6">
          <div class="row">
            <p>Java</p>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-1">
          <div class="row">
            <fa-icon [icon]="faSQL" size="2x"></fa-icon>
          </div>
        </div>

        <div class="col-6">
          <div class="row">
            <p>SQL</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
