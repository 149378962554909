<style>
  #canvas {
   width: 100%;
   height: 100%;
   display: block;
}
</style>

<div class="container">
  <div class="row">
    <!-- <div class="col">
      <h1 class="display-2">Justin Kuan</h1>
      <hr />
      <h3>Fullstack Developer</h3>
      <h3>Biomedical Engineering Student</h3>
      <h1 class="lead">
          Based in Vancouver, Canada
      </h1>

    </div> -->
    <div class="col">
      <div id='3dFrame'>
        <canvas id="canvas"> </canvas>
      </div>
    </div>
  </div>
</div>
