<style>
  p {
    color: black;
    font-size: 28px;
  }

  a {
    color: inherit;
  }

  div.card-bottom {
    position: absolute;
    bottom: 0px;
  }
</style>

<div class="container">
  <!-- <h1>Places I have worked at:</h1> -->

  <!-- <div class="card-group" >
    <div class="card" style="width: 18rem" *ngFor="let company of workList">
      <img src="{{company.Logo}}" class="card-img-top" alt="{{ company.Alt }}" />
      <div class="card-body">
        <h5 class="card-title"> {{ company.Name }}</h5>
        <p class="card-text"> {{ company.Position }} </p>
        <div class="card-bottom">
          <a href="#" class="btn btn-primary">Learn More</a>
        </div>
      </div>
    </div>
  </div> -->

  <div class="container" *ngFor="let company of workList">
    <br />
    <div class="container">
      <div class="row">
        <div class="col-3">
          <div class="row">
            <h4>
              <small class="text-muted">{{ company.Name }}</small>
            </h4>
          </div>
          <div class="row">
            <h4>
              <small class="text-muted">{{ company.Date }}</small>
            </h4>
          </div>
          <div class="row">
            <img
              src="{{ company.Logo }}"
              alt="{{ company.Alt }} + logo"
              max-width="200"
              width="100%"
            />
          </div>
        </div>
        <div class="col">
          <div class="row">
            <h1>
              {{ company.Position }}
            </h1>
          </div>
          <div class="row">
            <p>{{ company.Description }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
