import { Component, OnInit } from '@angular/core';
import { faAngular, faAws, faDocker, faJava, faPython } from '@fortawesome/free-brands-svg-icons';
import { faCode, faDatabase } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit {

  constructor() { }

  faPython = faPython;
  faAngular = faAngular;
  faAWS = faAws;
  faDocker = faDocker;
  faC = faCode;
  faJava = faJava;
  faSQL = faDatabase;

  ngOnInit(): void {
  }

}
