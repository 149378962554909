import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-experience',
  templateUrl: './experience.component.html',
  styleUrls: ['./experience.component.scss'],
})
export class ExperienceComponent implements OnInit {
  workList = [
    {
      Name: 'University of British Columbia',
      Position: 'Data Scientist',
      Date: "May 2021 - Present",
      Logo: '../assets/ubc.png',
      Alt: 'UBC Logo',
      Description:
        'As a Data Scientist at the University of British Columbia’s Chemistry Department, I am tasked with data mining large biochemical datasets for research projects and initiatives. I exclusively used Python tools such as NumPy and Pandas to mine terabytes worth of data and stored findings in SQL databases that I designed the schematics for.',
    },
    {
      Name: 'National Research Council Canada',
      Position: 'Web Developer',
      Date: "January 2021 - April 2021",
      Logo: '../assets/nrc.png',
      Alt: 'NRC Logo',
      Description:
        'As a web developer at the National Research Council of Canada (NRC), I built interfaces to render extremely large and complex data sets in meaningful ways. I have built visualization tools on a diverse set of platforms such as Drupal and PI Vision. To accomplish this, I extensively used tools such as OpenLayers, Angular, and SQL to aggregate, sort, filter, and portray data. ',
    },
    {
      Name: 'TTT Studios',
      Position: 'Backend Developer',
      Date: "April 2020 - December 2020",
      Logo: '../assets/TTT-studios.png',
      Alt: 'TTT Logo',
      Description:
        'At TTT Studios, I built conversational AIs hosted on AWS using Docker and Python. Additionally, my responsibilities as a backend developer include implementing DevOps workflows using Bash scripts on Bitbucket Pipelines, building RESTful APIs using Flask, and maintaining serverless backend infrastructures for our projects.',
    },
  ];

  constructor() {}

  ngOnInit(): void {}
}
