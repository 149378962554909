import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {

  constructor() { }

  collapseStatus = "collapse.show"

  ngOnInit(): void {
  }

  onClick() {
    if (this.collapseStatus === "collapse" ) {
      this.collapseStatus = "collapse.show"
    }
    else {
      this.collapseStatus = "collapse"
    }
    return
  }

}
